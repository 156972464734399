'use client';

import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import { analytics, analyticsBrowser } from '@/analytics/segment';
import { useGhostStore } from '@/legacy/state/store';
import { AnalyticsEventNames } from './Event.types';

interface Props {
  segmentClientId: string | undefined;
}

/* Component that initializes our Segment account so we can track analytics events & pageviews. */
export const SegmentInit = ({ segmentClientId }: Props) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { setAnalyticsHasInitialized, hasAnalyticsInitialized } = useGhostStore((store) => store);

  useEffect(() => {
    if (segmentClientId && !hasAnalyticsInitialized) {
      analyticsBrowser.load(
        { writeKey: segmentClientId },
        {
          integrations: {
            'Segment.io': {
              apiHost: 'seg-events.ghst.io/v1',
            },
          },
        },
      );
      setAnalyticsHasInitialized(true);
      analytics.track(AnalyticsEventNames.SEGMENT_ANALYTICS_INITIALIZED);
    }
  }, [segmentClientId, hasAnalyticsInitialized, setAnalyticsHasInitialized]);

  useEffect(() => {
    // Fire page events whenever url path or params update
    if (hasAnalyticsInitialized) {
      analytics.page();
    }
  }, [pathname, searchParams, hasAnalyticsInitialized]);

  return null;
};
