import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Button/Button.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Stack/Stack.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Typography/Typography.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@splinetool/react-spline/dist/react-spline.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["SegmentInit"] */ "/app/src/analytics/SegmentInit.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/ThemeRegistry/ThemeRegistry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/datadog/DatadogInit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DirtyContextProvider"] */ "/app/src/hooks/useBeforeUnload/index.tsx");
