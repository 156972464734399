'use client';

import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useId,
  useState,
} from 'react';
import { useRouter } from 'next/navigation';

let isForceRouting = false;
let activeIds: string[] = [];
let lastKnownHref: string;

// based on a solution from this github discussion:
// https://github.com/vercel/next.js/discussions/32231#discussioncomment-8606086

export const MESSAGE = 'Discard unsaved changes?';

const DirtyContext = createContext<{
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
}>({
  isDirty: false,
  setIsDirty: () => {},
});

export const DirtyContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);

  return <DirtyContext.Provider value={{ isDirty, setIsDirty }}>{children}</DirtyContext.Provider>;
};

export const useIsDirty = () => {
  return useContext(DirtyContext);
};

export const useBeforeUnload = (isActive = true) => {
  const { setIsDirty } = useIsDirty();
  useEffect(() => {
    setIsDirty(isActive);

    return () => {
      setIsDirty(false);
    };
  }, [isActive, setIsDirty]);

  const id = useId();
  // Handle <Link> clicks & onbeforeunload(attemptimg to close/refresh browser)
  useEffect(() => {
    if (!isActive) return;
    lastKnownHref = window.location.href;

    activeIds.push(id);

    // No need to double logic
    if (activeIds.length > 1)
      return () => {
        activeIds = activeIds.filter((x) => x !== id);
      };

    const handleAnchorClick = (e: Event) => {
      const anchor = e.currentTarget as HTMLAnchorElement;
      const targetUrl = anchor.href;
      const currentUrl = window.location.href;

      if (targetUrl !== currentUrl) {
        if (anchor.getAttribute('data-beforeunload-force')) {
          isForceRouting = true;
        }

        const res = beforeUnloadFn();
        if (!res) e.preventDefault();
        lastKnownHref = window.location.href;
      }
    };

    let anchorElements: HTMLAnchorElement[] = [];

    const disconnectAnchors = () => {
      anchorElements.forEach((anchor) => {
        anchor.removeEventListener('click', handleAnchorClick);
      });
    };

    const handleMutation = () => {
      disconnectAnchors();

      anchorElements = Array.from(document.querySelectorAll('a[href]'));
      anchorElements.forEach((anchor) => {
        anchor.addEventListener('click', handleAnchorClick);
      });
    };

    const mutationObserver = new MutationObserver(handleMutation);
    mutationObserver.observe(document.body, { childList: true, subtree: true });
    addEventListener('beforeunload', beforeUnloadFn);

    handleMutation(); // Also trigger right away.

    return () => {
      removeEventListener('beforeunload', beforeUnloadFn);
      disconnectAnchors();
      mutationObserver.disconnect();
      activeIds = activeIds.filter((x) => x !== id);
    };
  }, [isActive, id]);
};

const beforeUnloadFn = (event?: BeforeUnloadEvent) => {
  if (isForceRouting) return true;

  if (event) {
    event.returnValue = MESSAGE;
    return MESSAGE;
  } else {
    return confirm(MESSAGE);
  }
};

const BeforeUnloadProvider = ({ children }: React.PropsWithChildren) => {
  const router = useRouter();
  useEffect(() => {
    lastKnownHref = window.location.href;
  });

  // Hack nextjs13 popstate impl, so it will include route cancellation.
  // This Provider has to be rendered in the layout phase wrapping the page.
  useEffect(() => {
    let nextjsPopStateHandler: (...args: any[]) => void;

    function popStateHandler(...args: any[]) {
      useBeforeUnload.ensureSafeNavigation(
        () => {
          nextjsPopStateHandler(...args);
          lastKnownHref = window.location.href;
        },
        () => {
          router.replace(lastKnownHref, { scroll: false });
        },
      );
    }

    addEventListener('popstate', popStateHandler);
    const originalAddEventListener = window.addEventListener;
    window.addEventListener = (...args: any[]) => {
      if (args[0] === 'popstate') {
        nextjsPopStateHandler = args[1];
        window.addEventListener = originalAddEventListener;
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        originalAddEventListener(...args);
      }
    };

    return () => {
      window.addEventListener = originalAddEventListener;
      removeEventListener('popstate', popStateHandler);
    };
  }, [router]);

  return children;
};

useBeforeUnload.Provider = BeforeUnloadProvider;

useBeforeUnload.forceRoute = async (cb: () => void | Promise<void>) => {
  try {
    isForceRouting = true;
    await cb();
  } finally {
    isForceRouting = false;
  }
};

useBeforeUnload.ensureSafeNavigation = (
  onPerformRoute: () => void,
  onRouteRejected?: () => void,
) => {
  if (activeIds.length === 0 || beforeUnloadFn()) {
    onPerformRoute();
  } else {
    onRouteRejected?.();
  }
};
